import { CommonTranslations } from '../types';

const common: CommonTranslations = {
    id: 'id',
    loading: 'Loading...',
    index: 'Index',
    create: 'Create',
    add: 'Add Item',
    details: 'Details',
    edit: 'Edit',
    ok: 'Ok',
    'validation-error': 'Validation Error',
    reminder: {
        'main-form-submission': 'Please remember to save the main form',
    },
    submit: 'Submit',
    'submit-and-edit-details': 'Submit and Edit Details',
    'cancel': 'Cancel',
    delete: 'Delete',
    no: 'No',
    yes: 'Yes',
    since: 'Since: {{date}}',
    actions: 'Actions',
    print: 'Print',
    empty: 'No data available',
    day: {
        title: 'Day',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        short: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su',
        },
    },
    download: {
        pdf: 'Download PDF',
        'pdf-named': 'Download {{name}} PDF',
        'in-progress': 'Creating PDF...',
    },
    network: {
        error: {
            'no-connection': 'No network connection',
            '403': 'You do not have permission to perform this action',
            '404': 'Server could not find the resource',
            '500': 'Server could not process the request',
            '503': 'Server is under maintenance. Please try again later',
        },
    },
    message: {
        success: 'Success',
        error: 'Error',
    },
    reset: {
        question: 'Are you sure you want to reset?',
        description: 'All unsaved changes will be lost.',
    },
    validation: {
        'error-title': 'Validation Errors',
        required: 'This field is required',
        'empty-fields': 'Please fill in all required fields.',
    },
    input: {
        text: {
            placeholder: 'Please enter a value...',
        },
        number: {
            placeholder: 'Please enter a number...',
        },
        select: {
            none: 'None',
            placeholder: 'Please select...',
        },
        filter: {
            placeholder: 'Please select to filter...',
        },
        date: {
            format: 'MM-DD-YYYY',
            placeholder: 'Please select...',
        },
        tags: {
            placeholder: 'Please write and press Enter...',
        },
    },
    menu: {
        activities: {
            title: 'Activities',
            dashboard: {
                title: 'Dashboard',
            },
            'daily-report': {
                title: 'Daily Report',
            },
            documentation: {
                title: 'Documentation',
            },
        },
        planning: {
            title: 'Planning',
            'supplier-planning': {
                title: 'Supplier Planning',
            },
            'personnel-planning': {
                title: 'Personal Planning',
            },
            'service-planning': {
                title: 'Service Planning',
            },
        },
        files: {
            title: 'Files',
            'supplier-files': {
                title: 'Supplier Files',
            },
            'personnel-files': {
                title: 'Personal Files',
            },
            'service-files': {
                title: 'Service Files',
            },
        },
        finance: {
            title: 'Finance',
            'general-finances': {
                title: 'General Finances',
            },
            'personnel-finances': {
                title: 'Personal Finances',
            },
            'supplier-finances': {
                title: 'Sales Finances',
            },
            'service-finances': {
                title: 'Service Finances',
            },
        },
        inventory: {
            title: 'Inventory',
            warehouses: {
                title: 'Warehouses',
            }
        },
        'start-up': {
            title: 'Start Up',
        },
    },
};

export default common;
