import React from 'react';
import { PageContainer, ProTable } from '@ant-design/pro-components';
import { Button } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { getWarehouses } from '../../../utils/network/api/v1';
import cleanFilters from '../../../utils/cleanFilters';

const WarehouseIndex = () => {
    const { t } = useTranslation('inventory', {
        keyPrefix: 'warehouse.index',
    })
    const { t: ct } = useTranslation('common');
    const navigate = useNavigate();
    const location = useLocation();
    const queryStringParams = new URLSearchParams(location.search);

    const columns = [
        {
            title: ct('id'),
            dataIndex: 'id',
            width: '10%',
            sorter: true,
            initialValue: queryStringParams.get('id') || null,
        },
        {
            title: t('name'),
            dataIndex: 'name',
            width: '30%',
            sorter: true,
            initialValue: queryStringParams.get('name') || null,
        },
        {
            title: t('branches'),
            dataIndex: "branches",
            width: '40%',
            render: (o: any) => {
                const branches = o.map((d: any) => d.name).join(', ');

                return branches !== '' ? branches : '-';
            }
        },
        {
            title: ct('actions'),
            valueType: 'option',
            width: '20%',
            render: (_: any, record: any) => [
                <Button type="link" key="details" onClick={() => navigate(`/inventory/warehouse/${record.id}`)}>
                    {ct('details')}
                </Button>,
                <Button type="link" key="edit" onClick={() => navigate(`/inventory/warehouse/${record.id}/edit`)}>
                    {ct('edit')}
                </Button>,
            ],
        },
    ];

    return (
        <PageContainer>
            <ProTable
                rowKey="id"
                columns={columns}
                columnsState={{
                    persistenceType: 'sessionStorage',
                    persistenceKey: 'warehouses',
                }}
                toolBarRender={() => [
                    <Button key="1" type="primary" onClick={() => navigate('/inventory/warehouse/new')}>
                        <FileAddOutlined/> {ct('add')}
                    </Button>,
                ]}
                request={async (params, sort = {}) => {
                    const sortBy = Object.keys(sort).filter((key) => sort[key] !== null)[0] || null;
                    const sortOrder = sortBy ? sort[sortBy] : null;

                    const filters = cleanFilters({
                        id: params.id,
                        name: params.name,
                        branches: params.branches,
                    });

                    return await getWarehouses(
                        params.current,
                        params.pageSize,
                        sortBy,
                        sortOrder,
                        filters,
                    ).then((res) => {
                        return {
                            data: res.data.data,
                            success: true,
                            total: res.data.meta.total,
                        };
                    });
                }}
            />
        </PageContainer>
    );
};

export default WarehouseIndex;