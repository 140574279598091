import { PageContainer } from '@ant-design/pro-components';
import React from 'react'
import { useTranslation } from 'react-i18next';
import SupplierWizard from '../components/SupplierWizard';

const SupplierPage: React.FC = () => {
    const { t } = useTranslation('supplier-files');

    return (
        <PageContainer title={t('create-supplier-file.title')}>
            <SupplierWizard />
        </PageContainer>
    )
}

export default SupplierPage