import { SupplierFilesTranslations } from '../../types';

const SupplierFiles: SupplierFilesTranslations = {
    'index': {
        'name': 'Name',
        'company-field-of-activity': 'Unternehmensbranche',
        'categories': 'Kategorie',
        'tasks': 'Aufgaben',
        'primary-contact': 'Primärer Kontakt',
        'create-supplier-file': 'Lieferantenakte erstellen',
        'filters': {
            'state': {
                'options': {
                    'all': 'Alle',
                    'active': 'Aktiv',
                    'inactive': 'Inaktiv'
                }
            }
        }
    },
    'supplier-address': {
        'street': 'Straße',
        'number': 'Nummer',
        'country': 'Land',
        'city': 'Stadt',
        'zip_code': 'PLZ',
        'message': {
            'set-primary': {
                'on-success': 'Primäre Adresse erfolgreich gesetzt',
                'on-error': 'Fehler beim Setzen der primären Adresse',
            },
            'delete': {
                'on-success': 'Adresse erfolgreich gelöscht',
                'on-error': 'Fehler beim Löschen der Adresse',
            }
        },
    },
    'supplier-contact': {
        'date-of-birth': 'Geburtsdatum',
        'prefix': 'Namenspräfix',
        'full-name': 'Vollständiger Name',
        'suffix': 'Suffix',
        'email': 'E-Mail',
        'phone': 'Telefon',
        'mobile': 'Mobil',
        'fax': 'Fax',
        'company': 'Unternehmen',
        'department': 'Abteilung',
        'position': 'Position',
        'website': 'Webseite',
        'notes': 'Notizen',
        'message': {
            'set-primary': {
                'on-success': 'Primärer Kontakt erfolgreich gesetzt',
                'on-error': 'Fehler beim Setzen des primären Kontakts',
            },
            'delete': {
                'on-success': 'Kontakt erfolgreich gelöscht',
                'on-error': 'Fehler beim Löschen des Kontakts',
            }
        },
    },
    'create-supplier-file': {
        'title': 'Lieferantenakte erstellen',
    },
    'menu': {
        'supplier-information': 'Lieferant Informationen',
        'contacts': 'Kontakte',
        'addresses': 'Adressen',
        'performance-product-basics': 'Leistung Produkt Grundlagen',
        'loan-products': 'Leihprodukte',
    },
    'form': {
        'supplier-information': {
            'title': 'Lieferanteninformationen',
            'name': 'Name',
            'company-type': 'Unternehmensart',
            'company-field-of-activity': 'Unternehmensbranche',
            'categories': 'Kategorie',
            'tasks': 'Aufgaben',
        },
        'supplier-address': {
            'title': 'Lieferantenadresse',
            'street': 'Straße',
            'number': 'Nummer',
            'country': 'Land',
            'city': 'Stadt',
            'zip_code': 'PLZ',
        },
        'supplier-contacts': {
            'title': 'Lieferantenkontakte',
            'prefix': 'Namenspräfix',
            'full_name': 'Vollständiger Name',
            'suffix': 'Suffix',
            'date_of_birth': 'Geburtsdatum',
            'email': 'E-Mail',
            'phone': 'Telefon',
            'mobile': 'Mobil',
            'fax': 'Fax',
            'company': 'Unternehmen',
            'department': 'Abteilung',
            'position': 'Position',
            'website': 'Webseite',
        },
    }
};

export default SupplierFiles;