import React from 'react';
import {
    CaretRightOutlined,
    LockFilled,
} from '@ant-design/icons';
import {
    LoginForm,
    ProFormText,
} from '@ant-design/pro-components';
import { Tabs, message, Alert, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { postResetPassword } from '../../../utils/network/api/v1';
import logo from '../../../assets/logo-full-black.svg';
import './styles.css'
import { useQuery } from '../../../utils/hooks';

const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<any>([]);
    const { t } = useTranslation('identity', {
        keyPrefix: 'reset-password',
    });
    const query = useQuery();
    const email = query.get('email');

    if (!email || !token) {
        Modal.error({
            title: t('error-title'),
            content: t('error-content'),
            onOk() {
                navigate('/identity/login');
            },
        })
    }

    const handleSubmit = async (values: any) => {
        setLoading(true);
        postResetPassword({
            email: email!,
            token: token!,
            password: values.password,
            password_confirmation: values.password_confirmation,
        }).then((response) => {
            if (response.data) {
                message.success(t('success'), 10);
                navigate('/identity/login');
            }
        }).catch((e) => {
            if (e.response && e.response.status === 422) {
                setErrors(e.response.data.errors);
            } else {
                message.error(e.message)
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <div>
            <LoginForm
                logo={
                    <img
                        alt={"PatronX OPDS"}
                        width={328}
                        src={logo}
                    />
                }
                submitter={{
                    searchConfig: {
                        submitText: t('submit'),
                    },
                    submitButtonProps: {
                        loading,
                    },
                }}
                onFinish={handleSubmit}
            >
                <Tabs
                    activeKey="account"
                    items={[{
                        key: 'account',
                        label: t('title'),
                        children: (
                            <>
                                <Alert
                                    message={t('identity-verification-notice')}
                                    style={{
                                        marginBottom: 24,
                                    }}
                                    icon={<LockFilled/>}
                                    type="success"
                                    showIcon
                                />
                                <ProFormText.Password
                                    name="password"
                                    fieldProps={{
                                        size: 'large',
                                        prefix: <CaretRightOutlined className={'prefixIcon'}/>,
                                    }}
                                    placeholder={t('password.placeholder')}
                                    validateStatus={errors.password ? 'error' : 'success'}
                                    help={errors.password}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('password.required'),
                                        },
                                    ]}
                                />
                                <ProFormText.Password
                                    name="password_confirmation"
                                    fieldProps={{
                                        size: 'large',
                                        prefix: <CaretRightOutlined className={'prefixIcon'}/>,
                                    }}
                                    placeholder={t('password-confirmation.placeholder')}
                                    validateStatus={errors.password_confirmation ? 'error' : 'success'}
                                    help={errors.password_confirmation}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('password-confirmation.required'),
                                        },
                                    ]}
                                />
                            </>
                        ),
                    }]}
                />
            </LoginForm>
        </div>
    );
};

export default ResetPassword;
