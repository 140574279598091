import React from 'react';
import { ContactResource, deleteSupplierContact, setSupplierContactAsPrimary } from '../../../../../../../utils/network/api/v1';
import { Button, Card, message } from 'antd';
import { DeleteOutlined, EditOutlined, KeyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

type SupplierContactProps = {
    loading: boolean;
    supplierId: number;
    contact: ContactResource;
    onEdit: () => void;
    disableButtons?: boolean;
    onDeleteSuccess: () => void;
    onSetPrimarySuccess: () => void;
    style?: React.CSSProperties;
}

const gridStyles = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
}

const SupplierContact: React.FC<SupplierContactProps> = ({
    supplierId,
    contact: c,
    disableButtons,
    onEdit,
    onDeleteSuccess,
    onSetPrimarySuccess,
    loading,
    style,
}) => {
    const [messageApi] = message.useMessage();

    const { t } = useTranslation('supplier-files', {
        keyPrefix: 'supplier-contact',
    });
    
    const onDelete = () => {
        messageApi.open({
            content: t('message.delete.loading'),
            duration: 0,
            type: 'loading',
        });

        deleteSupplierContact(supplierId, c.id).then(() => {
            onDeleteSuccess();

            messageApi.destroy();
            message.success(t('message.delete.on-success'));
        }).catch(() => {
            messageApi.destroy();
            message.error(t('message.delete.on-error'));
        }).finally(() => {
            messageApi.destroy();
        });
    }
    
    const onClickSetPrimary = () => {
        messageApi.open({
            content: t('message.set-primary.loading'),
            duration: 0,
            type: 'loading',
        });

        setSupplierContactAsPrimary(supplierId, c.id).then(() => {
            messageApi.destroy();
            onSetPrimarySuccess();

            message.success(t('message.set-primary.on-success'));
        }).catch(() => {
            messageApi.destroy();
            message.error(t('message.set-primary.on-error'));
        }).finally(() => {
            messageApi.destroy();
        });
    }
    
    const actions: React.ReactNode[] = [
        <Button key="primary_contact" type="link" disabled={disableButtons} onClick={onClickSetPrimary}>
            <KeyOutlined style={{ color: disableButtons ? 'lightgreen' : 'green' }} />
        </Button>,
        <Button key="edit" type="link" disabled={disableButtons} onClick={onEdit}>
            <EditOutlined />
        </Button>,
        <Button key="delete" type="link" disabled={disableButtons} onClick={onDelete}>
            <DeleteOutlined style={{ color: disableButtons ? 'pink' : 'red' }} />
        </Button>,
    ];

    return (
        <Card loading={loading} style={{ minWidth: 300, ...style }} actions={actions}>
            <Card.Meta
                avatar={c.is_primary ? <KeyOutlined /> : undefined}
                title={[c.prefix, c.full_name, c.suffix].filter(Boolean).join(' ')}
                description={
                    <>
                        {/* Date of Birth */}
                        <span><b>{t('date-of-birth')}:</b> {c.date_of_birth || '-'}</span>

                        {/* Relational Info */}
                        <div style={gridStyles}>
                            <span><b>{t('company')}:</b> {c.company || '-'}</span>
                            <span><b>{t('department')}:</b> {c.department || '-'}</span>
                            <span><b>{t('position')}:</b> {c.position || '-'}</span>
                        </div>

                        {/* Website, Phones, and Email */}
                        <div style={gridStyles}>
                            <span><b>{t('phone')}:</b> {c.phone || '-'}</span>
                            <span><b>{t('mobile')}:</b> {c.mobile || '-'}</span>
                            <span><b>{t('fax')}:</b> {c.fax || '-'}</span>
                        </div>

                        <div style={gridStyles}>
                            <span><b>{t('website')}:</b> {c.website || '-'}</span>
                            <span><b>{t('email')}:</b> {c.email || '-'}</span>
                        </div>
                    </>
                }
            />
        </Card>
    );
};


export default SupplierContact;