import React from 'react';
import { useMount } from 'ahooks';
import { PageContainer, PageLoading } from '@ant-design/pro-components';
import { useNavigate, useParams } from 'react-router-dom';
import { getWarehouse, putWarehouse } from '../../../utils/network/api/v1';
import WarehouseForm from '../WarehouseForm';
import { Result } from 'antd';

const EditWarehouseForm: React.FC = () => {
    const navigate = useNavigate();
    const [failed, setFailed] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [warehouse, setWarehouse] = React.useState<any>(null);
    const params = useParams()
    const { id } = params;

    useMount(() => {
        if (!id) { return }

        getWarehouse(id).then((res) => {
            console.log(res.data)
            setWarehouse({
                ...res.data,
                branches: res.data.branches.map((branch: any) => (branch.id)),
            });
        }).catch((e) => {
            console.error(e);
            setFailed(true);
            setWarehouse(null);
        }).finally(() => {
            setLoading(false);
        })
    });

    if (loading) {
        return <PageLoading />
    }

    if (failed) {
        return (
            <PageContainer>
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                />
            </PageContainer>
        )
    }

    return (
        <PageContainer>
            <WarehouseForm
                w={warehouse}
                store={(values) => putWarehouse(id!, values)}
                afterSubmit={_ => {
                    navigate({
                        pathname: '/inventory/warehouse',
                        // search: createSearchParams({name: values.name}).toString(),
                    });
                }}
            />
        </PageContainer>
    )
}

export default EditWarehouseForm;