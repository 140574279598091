import { InventoryTranslations } from '../../types';

const Inventory: InventoryTranslations = {
    'warehouse': {
        'index': {
            'name': 'Name',
            'branches': 'Filialen',
        },
        'create': {
            'title': 'Neues Lager',
        },
        'edit': {
            'title': 'Lager Bearbeiten',
        },
        'form': {
            'name': 'Name',
            'branches': 'Filialen',
            'is_active': 'Aktiv',
            'general': {
                'title': 'Allgemein Informationen',
            },
            'configuration': {
                'title': 'Konfiguration',
                'branches': {
                    'title': 'Filialen',
                },
                'alert': {
                    'message': 'Die Auswahl der Filialen wird sofort synchronisiert. Das Entfernen einer bestehenden Filiale aus dem Lager deaktiviert die Integrationen sofort.',
                }
            }
        }
    },
}

export default Inventory;