import React from 'react';
import { AddressResource, deleteSupplierAddress, setSupplierAddressAsPrimary } from '../../../../../../../utils/network/api/v1';
import { Button, Card, message } from 'antd';
import { DeleteOutlined, EditOutlined, KeyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

type SupplierAddressProps = {
    loading: boolean;
    supplierId: number;
    address: AddressResource;
    onEdit: () => void;
    disableButtons?: boolean;
    onDeleteSuccess: () => void;
    onSetPrimarySuccess: () => void;
    style?: React.CSSProperties;
}

const gridStyles = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
}

const SupplierAddress: React.FC<SupplierAddressProps> = ({
    supplierId,
    address: a,
    disableButtons,
    onEdit,
    onDeleteSuccess,
    onSetPrimarySuccess,
    loading,
    style,
}) => {
    const [messageApi] = message.useMessage();

    const { t } = useTranslation('supplier-files', {
        keyPrefix: 'supplier-address',
    });
    
    const onDelete = () => {
        messageApi.open({
            content: t('message.delete.loading'),
            duration: 0,
            type: 'loading',
        });

        deleteSupplierAddress(supplierId, a.id).then(() => {
            onDeleteSuccess();

            messageApi.destroy();
            message.success(t('message.delete.on-success'));
        }).catch(() => {
            messageApi.destroy();
            message.error(t('message.delete.on-error'));
        }).finally(() => {
            messageApi.destroy();
        });
    }
    
    const onClickSetPrimary = () => {
        messageApi.open({
            content: t('message.set-primary.loading'),
            duration: 0,
            type: 'loading',
        });

        setSupplierAddressAsPrimary(supplierId, a.id).then(() => {
            messageApi.destroy();
            onSetPrimarySuccess();

            message.success(t('message.set-primary.on-success'));
        }).catch(() => {
            messageApi.destroy();
            message.error(t('message.set-primary.on-error'));
        }).finally(() => {
            messageApi.destroy();
        });
    }
    
    const actions: React.ReactNode[] = [
        <Button key="primary_address" type="link" disabled={disableButtons} onClick={onClickSetPrimary}>
            <KeyOutlined style={{ color: disableButtons ? 'lightgreen' : 'green' }} />
        </Button>,
        <Button key="edit" type="link" disabled={disableButtons} onClick={onEdit}>
            <EditOutlined />
        </Button>,
        <Button key="delete" type="link" disabled={disableButtons} onClick={onDelete}>
            <DeleteOutlined style={{ color: disableButtons ? 'pink' : 'red' }} />
        </Button>,
    ];

    return (
        <Card loading={loading} style={{ minWidth: 300, ...style }} actions={actions}>
            <Card.Meta
                avatar={a.is_primary ? <KeyOutlined /> : undefined}
                description={
                    <>
                        <div style={gridStyles}>
                            <span><b>{t('street')}:</b> {a.street || '-'}</span>
                            <span><b>{t('number')}:</b> {a.number || '-'}</span>
                        </div>

                        <div style={gridStyles}>
                            <span><b>{t('country')}:</b> {a.country || '-'}</span>
                            <span><b>{t('city')}:</b> {a.city || '-'}</span>
                            <span><b>{t('zip_code')}:</b> {a.zip_code || '-'}</span>
                        </div>
                    </>
                }
            />
        </Card>
    );
};


export default SupplierAddress;