import { InventoryTranslations } from '../../types';

const Inventory: InventoryTranslations = {
    'warehouse': {
        'index': {
            'name': 'Name',
            'branches': 'Filialen',
        },
        'form': {
            'name': 'Name',
            'branches': 'Filialen',
        }
    },
}

export default Inventory;