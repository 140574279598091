import { ProTable, PageContainer, ProFormInstance, ActionType } from '@ant-design/pro-components';
import React, { useRef } from 'react';
import { getSupplierFiles, deleteSupplierFile, SupplierResource } from '../../../../utils/network/api/v1';
import cleanFilters from '../../../../utils/cleanFilters';
import { Button, message, Popconfirm } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SupplierFileIndex: React.FC = () => {
    const tableRef = useRef<ActionType>();
    const formRef = useRef<ProFormInstance>();
    const navigate = useNavigate();
    const [
        stateFilter,
        setStateFilter,
    ] = React.useState<string>('all');
    const { t } = useTranslation('supplier-files', {
        keyPrefix: 'index',
    });
    const { t: ct } = useTranslation('common');

    const [idsInUse, setIdsInUse] = React.useState<number[]>([]);

    const onEdit = (id: number) => {
        setIdsInUse([...idsInUse, id]);
        
        navigate(`/files/supplier/${id}/edit`);
    }

    const onDelete = (id: number) => {
        if (idsInUse.includes(id)) {
            return;
        }

        setIdsInUse([...idsInUse, id]);

        deleteSupplierFile(id).then(() => {
            message.success(t('delete-success'));
            tableRef.current?.reload();
        }).catch(() => {
            message.error(t('delete-error'));
        }).finally(() => {
            setIdsInUse(idsInUse.filter((i) => i !== id));
        });
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 48,
            sorter: true,
            initialValue: '',
        },
        {
            'title': t('name'),
            'dataIndex': 'name',
            'key': 'name',
        },
        {
            'title': t('company-field-of-activity'),
            'dataIndex': 'company_field_of_activities',
            'key': 'company_field_of_activities',
            render: (_: any, record: SupplierResource) => record.company_field_of_activities?.join(', ') ?? '-',
        },
        {
            'title': t('categories'),
            'dataIndex': 'categories',
            render: (_: any, record: SupplierResource) => record.categories?.join(', ') ?? '-',
        },
        {
            'title': t('tasks'),
            'dataIndex': 'tasks',
            'key': 'tasks',
            render: (_: any, record: SupplierResource) => record.tasks?.join(', ') ?? '-',
        },
        {
            'title': t('primary-contact'),
            'dataIndex': 'primary_contact.full_name',
            'key': 'primary_contact.full_name',
            render: (_: any, record: SupplierResource) => record.primary_contact?.full_name ?? '-',
        },
        {
            'title': ct('actions'),
            'key': 'actions',
            'valueType': 'option',
            'render': (_: any, record: SupplierResource) => [
                <Button
                    type='link'
                    key="edit"
                    onClick={() => onEdit(record.id)}
                    disabled={idsInUse.includes(record.id)}
                >
                    {ct('edit')}
                </Button>,
                <Popconfirm
                    key="delete"
                    title={ct('delete-confirm')}
                    onConfirm={() => onDelete(record.id)}
                    okText={ct('yes')}
                    cancelText={ct('no')}
                    disabled={idsInUse.includes(record.id)}
                >
                    <Button type='link'>{ct('delete')}</Button>
                </Popconfirm>
            ]
        }
    ]
    
    return (
        <PageContainer title="Supplier Files">
            <ProTable
            actionRef={tableRef}
                columns={columns}
                toolbar={{
                    menu: {
                        type: 'tab',
                        activeKey: stateFilter,
                        items: [
                            {
                                key: 'all',
                                label: t('filters.state.options.all'),
                            },
                            {
                                key: 'active',
                                label: t('filters.state.options.active'),
                            },
                            {
                                key: 'inactive',
                                label: t('filters.state.options.inactive'),
                            },
                        ],
                        onChange: (key) => {
                            setStateFilter(key as string);

                            formRef.current?.submit();
                        },
                    },
                }}
                toolBarRender={() => [
                    <Button key="1" type="primary" onClick={() => navigate('/files/supplier/new')}>
                        <FileAddOutlined/> {t('create-supplier-file')}
                    </Button>,
                ]}
                request={async (params = {}, sort = {}) => {
                    const sortBy = Object.keys(sort).filter((key) => sort[key] !== null)[0] || null;
                    const sortOrder = sortBy ? sort[sortBy] : null;

                    const filters = cleanFilters({
                        id: params.id,
                        full_name: params.full_name,
                        company_id: params.company_id,
                        role_id: params.role_id,
                        branch_id: params.branch_id,
                    });

                    return await getSupplierFiles(
                        params.current,
                        params.pageSize,
                        sortBy,
                        sortOrder,
                        filters,
                    ).then((res) => {
                        return {
                            data: res.data.data,
                            success: true,
                            total: res.data.meta.total,
                        };
                    });
                }}
                rowKey={(record) => record.id}
            />
        </PageContainer>
    );
}

export default SupplierFileIndex