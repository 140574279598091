import { Card, Button, message } from 'antd';
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { createSchemaField } from '@formily/react';
import { FormGrid, GridColumn, Form, FormItem, Input, Select } from '@formily/antd-v5';
import { createForm, FormPathPattern } from '@formily/core';
import { getListSupplierCmpCategories, getListSupplierCmpFieldOfActivities, getListSupplierCmpTasks } from '../../../../../../utils/network/api/v1';
import { getSupplier, storeSupplier, updateSupplier, SupplierResource } from '../../../../../../utils/network/api/v1';
import { getAsyncDataSourceWithLookupHandler, asyncDataSourceWithLookup } from '../../../../../../utils/asyncDataSourceWithLookup';

const creator = (afterSubmit: (data: SupplierResource) => void) => (values: any) =>
    storeSupplier(values).then((data) => {
        afterSubmit(data.data);
    });

const updator = (id: string, afterSubmit: (data: SupplierResource) => void) => (values: any) =>
    updateSupplier(id, values).then((data) => {
        afterSubmit(data.data);
    })

const SchemaField = createSchemaField({
    components: {
        Button,
        FormItem,
        FormGrid,
        GridColumn,
        Card,
        Input,
        Select,
    },
});

type SupplierInformationFormProps = {
    id?: string;
    afterSubmit: (data: SupplierResource) => void;
}

const SupplierInformationForm: React.FC<SupplierInformationFormProps> = ({
    id,
    afterSubmit,
}) => {
    const [supplier, setSupplier] = React.useState<SupplierResource|undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [, setValidationErrors] = React.useState<any>();
    const { t } = useTranslation('supplier-files');
    const { t: ct } = useTranslation('common');

    useEffect(() => {
        if (id) {
            setLoading(true);
            getSupplier(id!).then((data) => {
                setSupplier(data.data);
            }).catch((e) => {
                message.error(t('form.submit.result.error'), 10);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [id, t]);

    const form = useMemo(() => createForm({
        effects: () => {
            asyncDataSourceWithLookup('company_field_of_activities', getAsyncDataSourceWithLookupHandler(getListSupplierCmpFieldOfActivities));
            asyncDataSourceWithLookup('categories', getAsyncDataSourceWithLookupHandler(getListSupplierCmpCategories));
            asyncDataSourceWithLookup('tasks', getAsyncDataSourceWithLookupHandler(getListSupplierCmpTasks));
        },
        initialValues: supplier,
    }), [supplier]);

    const handleSubmit = async (values: any) => {
        if (loading) return;
        
        const cb = (data: any) => {
            setSupplier(data);
            afterSubmit(data);
        };
        
        
        const sendData =  !id ? creator(cb) : updator(id, cb);
        
        setLoading(true);
        let errValidation: any = [];
        sendData(values).catch((e) => {
            message.error(t('form.submit.result.error'), 10);

            if (e.response && e.response?.status === 422) {
                errValidation = e.response?.data?.errors;

                window.scrollTo(0, 0);
            }
        }).finally(() => {
            setLoading(false);
            setValidationErrors(errValidation);
        });
    };

    return (
        <Form
            form={form}
            labelCol={8}
            wrapperCol={14}
            onAutoSubmit={handleSubmit}
            onAutoSubmitFailed={(feedbacks) => {
                const targetField = form.query(feedbacks[0].path as FormPathPattern).take();

                // @ts-ignore
                const targetId = targetField?.component[1]?.id;
                if (targetId) {
                    document.getElementById(targetId)?.focus();
                }
            }}
            >
                <SchemaField>
                    <SchemaField.Void
                        x-component="Card"
                        x-component-props={{ title: t('form.supplier-information.title') }}
                        name="cxk691otwox"
                    >
                        <SchemaField.Void
                            x-component="FormGrid"
                            name="m0njwz63vsw"
                        >
                            <SchemaField.Void
                                x-component="FormGrid.GridColumn"
                                name="sguhotveowp"
                            >
                                <SchemaField.String
                                    title={t('form.supplier-information.name')}
                                    x-decorator="FormItem"
                                    x-component="Input"
                                    x-component-props={{
                                        placeholder: ct('input.text.placeholder'),
                                        id: 'name',
                                    }}
                                    x-validator={[
                                        {
                                            required: true,
                                            message: ct('validation.required'),
                                        },
                                    ]}
                                    name="name"
                                />
                                <SchemaField.String
                                    title={t('form.supplier-information.company-type')}
                                    x-decorator="FormItem"
                                    x-component="Select"
                                    x-component-props={{
                                        placeholder: ct('input.text.placeholder'),
                                        id: 'name',
                                        options: [
                                            { label: 'GmbH', value: 'GmbH' },
                                            { label: 'AG', value: 'AG' },
                                            { label: 'e.K.', value: 'e.K.' },
                                        ],
                                        style: { width: '100%' },
                                    }}
                                    x-validator={[
                                        {
                                            required: true,
                                            message: ct('validation.required'),
                                        },
                                    ]}
                                    name="company_type"
                                />
                                <SchemaField.Markup
                                    title={t('form.supplier-information.company-field-of-activity')}
                                    x-decorator="FormItem"
                                    x-component="Select"
                                    x-component-props={{
                                        placeholder: ct('input.tags.placeholder'),
                                        labelInValue: false,
                                        allowClear: true,
                                        mode: 'tags',
                                        tokenSeparators: [','],
                                    }}
                                    name="company_field_of_activities"
                                />
                                <SchemaField.Markup
                                    title={t('form.supplier-information.categories')}
                                    x-decorator="FormItem"
                                    x-component="Select"
                                    x-component-props={{
                                        placeholder: ct('input.tags.placeholder'),
                                        labelInValue: false,
                                        mode: 'tags',
                                        showSearch: true,
                                        filterOption: false,
                                        tokenSeparators: [','],
                                    }}
                                    name="categories"
                                />
                                <SchemaField.Markup
                                    title={t('form.supplier-information.tasks')}
                                    x-decorator="FormItem"
                                    x-component="Select"
                                    x-component-props={{
                                        placeholder: ct('input.tags.placeholder'),
                                        labelInValue: false,
                                        mode: 'tags',
                                        showSearch: true,
                                        filterOption: false,
                                        tokenSeparators: [','],
                                    }}
                                    name="tasks"
                                />
                            </SchemaField.Void>
                        </SchemaField.Void>
                        <SchemaField.Void
                            x-component="div"
                            x-component-props={{
                                style: { display: 'flex', justifyContent: 'flex-end' },
                            }}
                        >
                            <SchemaField.Void
                                x-disabled={loading}
                                x-component="Button"
                                x-component-props={{
                                    type: 'primary',
                                    htmlType: 'submit',
                                    children: ct('submit-and-edit-details'),
                                }}
                                name="submit"
                            />
                        </SchemaField.Void>
                    </SchemaField.Void>
                </SchemaField>
        </Form>
    );
}

export default SupplierInformationForm;