import { useRequest } from 'ahooks';
import { Button, Empty, Result } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { getSupplierContacts } from '../../../../../../../utils/network/api/v1';
import SupplierContact from './SupplierContact';
import SupplierContactForm from './SupplierContactForm';

type SupplierContactsProps = {
    id: string;
}

const SupplierContacts: React.FC<SupplierContactsProps> = ({ id }) => {
    const [editing, setEditing] = React.useState<number[]>([]);
    const { data, error, loading, refresh } = useRequest(() => getSupplierContacts(id));
    const [showAddForm, setShowAddForm] = React.useState(false);

    const onEditContact = (id: number) => {
        if (editing.includes(id)) {
            return;
        }

        setEditing([...editing, id]);
    }

    const onCreateContactSubmitted = () => {
        refresh();
        setShowAddForm(false);
    }

    const onEditContactSubmitted = (id: number) => {
        refresh();
        setEditing(editing.filter((e) => e !== id));
    }

    const onEditContactCancelled = (id: number) => {
        setEditing(editing.filter((e) => e !== id));
    }

    const contacts = data?.data || [];
    
    const { t: ct } = useTranslation('common');
    const { t } = useTranslation('supplier-files', {
        keyPrefix: 'form.supplier-contacts',
    });

    if (error) {
        return <Result status="error" title={t('error')} subTitle={error.message} />
    }

    return (
        <>
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingRight: '2px',
                paddingLeft: '2px',
            }}
        >
            <h3>{t('title')}</h3>
            <Button
                type="primary"
                disabled={editing.length > 0}
                onClick={() => setShowAddForm(true)}
            >
                {ct('add')}
            </Button>
        </div>
        {showAddForm && (
            <div style={{ marginBottom: '16px' }}>
                <SupplierContactForm
                    supplierId={parseInt(id)}
                    onSubmit={() => onCreateContactSubmitted()}
                    onCancel={() => setShowAddForm(false)}
                />
            </div>
        )}
        <div>
            {!showAddForm && contacts?.length === 0 && (
                <Empty description={ct('empty')} />
            )}
            
            {contacts?.map((contact) => !editing.includes(contact.id) ? (
                <SupplierContact
                    supplierId={parseInt(id)}
                    key={contact.id}
                    contact={contact}
                    loading={loading}
                    onEdit={() => onEditContact(contact.id)}
                    onDeleteSuccess={refresh}
                    onSetPrimarySuccess={refresh}
                    disableButtons={showAddForm}
                    style={{ marginBottom: '16px' }}
                />
            ) : (
                <SupplierContactForm
                    key={contact.id}
                    supplierId={parseInt(id)}
                    contact={contact}
                    onSubmit={() => onEditContactSubmitted(contact.id)}
                    onCancel={() => onEditContactCancelled(contact.id)}
                />
            ))}
        </div>
        </>
    )
}

export default SupplierContacts;