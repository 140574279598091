import React from 'react';
import { PageContainer, PageLoading } from '@ant-design/pro-components';
import { Timeline, Tabs, Descriptions, DescriptionsProps, Result, Table, TabsProps, Card, Alert } from 'antd';
import { useMount } from 'ahooks';
import { useParams } from 'react-router-dom';
import { getWarehouse, WarehouseResource } from '../../../utils/network/api/v1';
import { LoadingOutlined } from '@ant-design/icons';

const WarehouseDetails: React.FC = () => {
    const [failed, setFailed] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [warehouse, setWarehouse] = React.useState<WarehouseResource|null>(null);
    const params = useParams()
    const { id } = params;
    console.log(id)

    useMount(() => {
        if (!id) { return }

        getWarehouse(id).then((res) => {
            setWarehouse(res.data);
        }).catch((e) => {
            setFailed(true);
            setWarehouse(null);
        }).finally(() => {
            setLoading(false);
        })
    });

    if (!warehouse || loading) {
        return <PageLoading />
    }

    if (failed) {
        return (
            <PageContainer>
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                />
            </PageContainer>
        )
    }

    const descriptionItems: DescriptionsProps['items'] = [
        {
            key: 'name',
            label: 'Name',
            children: warehouse!.name,
        },
        {
            key: 'branches',
            label: 'branches',
            children: warehouse!.branches.map((b) => b.name).join(', '),
        },
    ]

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Produkte',
            children: (
                <Card title="Produkte" style={{ marginTop: 16 }}>
                    <Alert type="info" message="Entwicklung im Gange" />
                    <Table />
                </Card>
            ),
        },
        {
            key: '2',
            label: 'Lieferungen',
            children: (
                <Card title="Lieferungen" style={{ marginTop: 16 }}>
                <Timeline
                    items={[
                        {
                            color: 'blue',
                            dot: <LoadingOutlined />,
                            children: (
                                <>
                                    <p>Lieferung für morgen geplant</p>
                                    <p>Liefertermin: 12:00 - 14:00</p>
                                </>
                            ),
                        },
                        {
                            color: 'red',
                            children: (
                                <>
                                    <p>Zustellung fehlgeschlagen</p>
                                    <p>Voraussichtlicher Liefertermin: Yesterday</p>
                                    <p>Beschreibung: Die Produkte werden morgen geliefert</p>
                                </>
                            )
                        },
                        {
                            color: 'green',
                            children: (
                                <>
                                    <p>Lieferung ist angekommen</p>
                                    <p>Liefertermin: 1 week ago</p>
                                </>
                            ),
                        },
                        {
                            children: (
                                <>
                                    <p>Technical testing 1</p>
                                    <p>Technical testing 2</p>
                                    <p>Technical testing 3 2015-09-01</p>
                                </>
                            ),
                        },
                        {
                            color: 'gray',
                            children: (
                                <>
                                    <p>Technical testing 1</p>
                                    <p>Technical testing 2</p>
                                    <p>Technical testing 3</p>
                                </>
                            ),
                        },
                        {
                            color: 'gray',
                            children: (
                                <>
                                    <p>Technical testing 1</p>
                                    <p>Technical testing 2</p>
                                    <p>Technical testing 3</p>
                                </>
                            ),
                        },
                    ]}
                />
                </Card>
            ),
        },
        {
            key: '3',
            label: 'Transaktionen',
            children: (
                <Card title="Transaktionen" style={{ marginTop: 16 }}>
                    <Alert type="info" message="Entwicklung im Gange" />
                    <Table />
                </Card>
            ),
        },
    ];

    return (
        <PageContainer title="Warehouse Details">
            <Descriptions title="Warehouse Information" bordered items={descriptionItems} />

            <Tabs defaultActiveKey="1" items={items} tabBarStyle={{ marginBottom: 0, marginTop: 32 }} />
        </PageContainer>
    );
}

export default WarehouseDetails;