import { Card, Button, message } from 'antd';
import { createSchemaField } from '@formily/react';
import { createForm, FormPathPattern } from '@formily/core';
import { FormGrid, GridColumn, Form, FormLayout, FormItem, Input, DatePicker } from '@formily/antd-v5';
import React, { useMemo } from 'react';
import { ContactResource, storeSupplierContact, updateSupplierContact } from '../../../../../../../utils/network/api/v1';
import { useTranslation } from 'react-i18next';

type SupplierContactResource = {
    id?: number;
}

const creator = (supplierId: number, afterSubmit: (data: SupplierContactResource) => void) => (values: any) =>
    storeSupplierContact(supplierId, values).then((data) => {
        afterSubmit(data.data);
    });

const updator = (supplierId: number, id: number, afterSubmit: (data: SupplierContactResource) => void) => (values: any) =>
    updateSupplierContact(supplierId, id, values).then((data) => {
        afterSubmit(data.data);
    })

type SupplierContactFormProps = {
    supplierId: number,
    contact?: ContactResource;
    onSubmit: () => void;
    onCancel: () => void;
}

const SchemaField = createSchemaField({
    components: {
        Button,
        FormItem,
        FormGrid,
        GridColumn,
        Card,
        Input,
        DatePicker,
    },
});

const SupplierContactForm: React.FC<SupplierContactFormProps> = ({ supplierId, contact: c, onSubmit, onCancel }) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [, setValidationErrors] = React.useState<any>();
    const form = useMemo(() => createForm({
        initialValues: c,
    }), [c]);

    const { t } = useTranslation('supplier-files', {
        keyPrefix: 'form.supplier-contacts',
    });
    const { t: ct } = useTranslation('common');

    const afterSubmit = async (values: any) => {
        if (loading) return;
        
        const cb = (_: any) => {
        };

        const sendData =  !c?.id ? creator(supplierId, cb) : updator(supplierId, c!.id, cb);
        
        setLoading(true);
        let errValidation: any = [];
        sendData(values).then(() => {
            message.success(ct('message.success'), 10);
            onSubmit();
        }).catch((e) => {
            message.error(ct('message.error'), 10);

            if (e.response && e.response?.status === 422) {
                errValidation = e.response?.data?.errors;

                window.scrollTo(0, 0);
            }
        }).finally(() => {
            setLoading(false);
            setValidationErrors(errValidation);
        });
    };
    
    return (
        <Form
            form={form}
            labelCol={8}
            wrapperCol={14}
            onAutoSubmit={afterSubmit}
            onAutoSubmitFailed={(feedbacks) => {
                const targetField = form.query(feedbacks[0].path as FormPathPattern).take();

                // @ts-ignore
                const targetId = targetField?.component[1]?.id;
                if (targetId) {
                    document.getElementById(targetId)?.focus();
                }
            }}
        >
                <FormLayout layout="vertical">
                <SchemaField>
                    <SchemaField.Void x-component="Card">
                        <SchemaField.Void x-component="FormGrid">
                            <SchemaField.String
                                title={t('prefix')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'prefix',
                                }}
                                name="prefix"
                            />
                            <SchemaField.String
                                title={t('full_name')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'full_name',
                                }}
                                name="full_name"
                            />
                            <SchemaField.String
                                title={t('suffix')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'suffix',
                                }}
                                name="suffix"
                            />
                        </SchemaField.Void>

                        <SchemaField.Void x-component="FormGrid">
                            <SchemaField.String
                                title={t('date_of_birth')}
                                x-decorator="FormItem"
                                x-component="DatePicker"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'date_of_birth',
                                }}
                                name="date_of_birth"
                            />        
                        </SchemaField.Void>

                        <SchemaField.Void x-component="FormGrid">
                            <SchemaField.String
                                title={t('company')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'company',
                                }}
                                name="company"
                            />
                            <SchemaField.String
                                title={t('department')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'department',
                                }}
                                name="department"
                            />
                            <SchemaField.String
                                title={t('position')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'position',
                                }}
                                name="position"
                            />
                        </SchemaField.Void>

                        <SchemaField.Void x-component="FormGrid">
                            <SchemaField.String
                                title={t('website')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'website',
                                }}
                                name="website"
                            />
                            <SchemaField.String
                                title={t('phone')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'phone',
                                }}
                                name="phone"
                            />
                            <SchemaField.String
                                title={t('mobile')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'mobile',
                                }}
                                name="mobile"
                            />
                            <SchemaField.String
                                title={t('fax')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'fax',
                                }}
                                name="fax"
                            />
                            <SchemaField.String
                                title={t('email')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'email',
                                }}
                                name="email"
                            />
                        </SchemaField.Void>
                        <SchemaField.Void
                            x-component="div"
                            x-component-props={{
                                style: { display: 'flex', justifyContent: 'flex-end' },
                            }}
                        >
                            <SchemaField.Void
                                x-disabled={loading}
                                x-component="Button"
                                x-component-props={{
                                    type: 'primary',
                                    htmlType: 'submit',
                                    children: ct('submit'),
                                }}
                                name="submit"
                            />
                            <SchemaField.Void
                                x-component="Button"
                                x-component-props={{
                                    onClick: () => onCancel(),
                                    children: ct('cancel'),
                                }}
                                name="cancel"
                            />
                        </SchemaField.Void>
                    </SchemaField.Void>

                </SchemaField>
                </FormLayout>
            </Form>
    );
}

export default SupplierContactForm;