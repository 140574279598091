import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Menu, Row, Card } from 'antd';
import { createMenuItems } from './utils';
import SupplierInformationForm from './steps/SupplierInformationForm';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import SupplierContacts from './steps/SupplierContacts/SupplierContacts';
import SupplierAddresses from './steps/SupplierAddresses';

type SupplierWizardProps = {
}

const SupplierWizard: React.FC<SupplierWizardProps> = () => {
    const navigate = useNavigate();
    // get the :id from the URL
    const { id } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedKey = searchParams.get('view') ?? 'supplier-information';

    const { t } = useTranslation('supplier-files');

    let view = null;
    switch (selectedKey) {
        case 'supplier-information':
            view = (
                <SupplierInformationForm
                    id={id}
                    afterSubmit={(data) => {
                        if (!id) {
                            navigate(`/files/supplier/${data.id}/edit?view=supplier-contacts`);
                        }
                    }}
                />
            );
            break;
        case 'supplier-contacts':
            view = <SupplierContacts id={id!} />;
            break;
        case 'supplier-addresses':
            view = <SupplierAddresses id={id!} />;
            break;
        case 'supplier-performance-product-basics':
            view = 3;
            break;
        case 'supplier-loan-products':
            view = 4;
            break;
        default:
            view = 0;
            break;
    }
    
    return (
        <>
            <Card>
                <Row>
                    <Col span={6}>
                        <Menu
                            selectedKeys={[selectedKey]}
                            defaultSelectedKeys={[selectedKey]}
                            onClick={(e) => setSearchParams({view: e.key})}
                            style={{ marginBottom: 24 }}
                            items={createMenuItems(t, !id)}
                        />
                    </Col>
                    <Col span={18} style={{ paddingLeft: 24 }}>
                        { view }
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default SupplierWizard;