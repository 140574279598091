import React, { useEffect, useMemo } from 'react';
import {
    createForm,
    FormPathPattern,
    onFieldReact,
} from '@formily/core';
import { createSchemaField } from '@formily/react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Form,
    FormItem,
    DatePicker,
    Checkbox,
    Cascader,
    Editable,
    Input,
    NumberPicker,
    Switch,
    Password,
    PreviewText,
    Radio,
    Reset,
    Select,
    Space,
    Submit,
    TimePicker,
    Transfer,
    TreeSelect,
    Upload,
    FormGrid,
    FormLayout,
    FormTab,
    FormCollapse,
    ArrayTable,
    ArrayItems,
    ArrayCards,
    FormButtonGroup,
} from '@formily/antd-v5';
import { Card, Slider, Rate, Button } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { observable } from '@formily/reactive';
import { observer } from '@formily/reactive-react';
import {
    getListEmployeeSkills,
    uploadEmployeeProfilePhoto,
    getListEmployeeHealthDiseases,
    getListEmployeeHealthMedications,
    getListEmployeeHealthAddictions,
    getListEmployeeEducationSchool,
    getListBranches,
    getListRoles,
} from '../../../../../utils/network/api/v1';
import { useTranslation } from 'react-i18next';
import { residencePermitTypes } from './data';
import {
    fixDockerHost,
    validateUploadedPhoto,
    customUploadRequest,
    maritalStatusTypes,
    otherEmploymentTypes,
    asyncDataSource,
} from './utils';
import { getAsyncDataSourceWithLookupHandler, asyncDataSourceWithLookup } from '../../../../../utils/asyncDataSourceWithLookup';
import Text from './../../../../../components/Text';
import TranslatedText from './../../../../../components/TranslatedText';
import { PersonnelFormProps } from './types';
import sections from '../../NewPersonnelForm/sections';
import EditContractButton from './EditContractButton';
import DeleteContractButton from './DeleteContractButton';
import styles from './styles';

const SchemaField = createSchemaField({
    components: {
        Space,
        FormGrid,
        FormLayout,
        FormTab,
        FormCollapse,
        ArrayTable,
        ArrayItems,
        ArrayCards,
        FormItem,
        DatePicker,
        Checkbox,
        Cascader,
        Editable,
        Input,
        Text,
        TranslatedText,
        NumberPicker,
        Switch,
        Password,
        PreviewText,
        Radio,
        Reset,
        Select,
        Submit,
        TimePicker,
        Transfer,
        TreeSelect,
        Upload,
        Card,
        Slider,
        Rate,
        Button,
        EditContractButton,
        DeleteContractButton,
    },
});

const PersonnelForm = ({
    handleSubmit,
    personnel,
}: PersonnelFormProps) => {
    const { t } = useTranslation('personnel-files');
    const { t: ct } = useTranslation('common');

    const observables = observable({
        photo_loading: false,
        photo_url: '',
    });

    const form = useMemo(() => createForm({
        effects: () => {
            asyncDataSourceWithLookup('skills', getAsyncDataSourceWithLookupHandler(getListEmployeeSkills));
            asyncDataSourceWithLookup('health_diseases', getAsyncDataSourceWithLookupHandler(getListEmployeeHealthDiseases));
            asyncDataSourceWithLookup('health_medications', getAsyncDataSourceWithLookupHandler(getListEmployeeHealthMedications));
            asyncDataSourceWithLookup('health_addictions', getAsyncDataSourceWithLookupHandler(getListEmployeeHealthAddictions));
            asyncDataSourceWithLookup('education_school', getAsyncDataSourceWithLookupHandler(getListEmployeeEducationSchool));
            asyncDataSource('probation_branch_id', async () => {
                return await getListBranches().then((data) => {
                    return data.data.map((item) => ({
                        label: item.name,
                        value: item.id,
                    }));
                });
            });
            asyncDataSource('application_role_id', async () => {
                return await getListRoles().then((data) => {
                    return data.data.map((item) => ({
                        label: item.name,
                        value: item.id,
                    }));
                });
            });
            onFieldReact('has_driving_license', (field) => {
                form.setFieldState('driving_license_number', (state) => {
                    // @ts-ignore
                    state.display = field.value ? 'visible' : 'hidden';
                });
            });
            onFieldReact('has_children', (field) => {
                form.setFieldState('children_count', (state) => {
                    // @ts-ignore
                    state.display = field.value ? 'visible' : 'hidden';
                });

                form.setFieldState('child_allowance', (state) => {
                    // @ts-ignore
                    state.display = field.value ? 'visible' : 'hidden';
                });
            });
            onFieldReact('has_health_passport', (field) => {
                form.setFieldState('health_passport_issue_date', (state) => {
                    // @ts-ignore
                    state.display = field.value ? 'visible' : 'hidden';
                });

                form.setFieldState('health_passport_expiration_date', (state) => {
                    // @ts-ignore
                    state.display = field.value ? 'visible' : 'hidden';
                });
            });
            onFieldReact('children_count', (field, form) => {
                const state: any = field.getState();

                form.setFieldState('employee_children', (s) => {
                    const childrenCount = state.value;
                    const items = s.value;

                    if (items.length < childrenCount) {
                        for (let i = items.length; i < childrenCount; i++) {
                            items.push({
                                name: '',
                                birth_date: '',
                            });
                        }
                    } else if (items && items.length > childrenCount) {
                        items.splice(childrenCount, items.length - childrenCount);
                    }

                    s.display = (state.display === 'visible' && childrenCount > 0)
                        ? 'visible'
                        : 'hidden';
                });
            });
            onFieldReact('other_employment', (field) => {
                form.setFieldState('other_employment_type', (state) => {
                    // @ts-ignore
                    state.display = field.value ? 'visible' : 'none';
                });
            });
            onFieldReact('other_employment_type', (field) => {
                form.setFieldState('other_employment_employer', (state) => {
                    // @ts-ignore
                    state.display = field.value === 'subject-to-social-insurance' ? 'visible' : 'none';
                });
            });
            onFieldReact('probation_day_count', (field, form) => {
                const state: any = field.getState();

                form.setFieldState('probation_days', (s) => {
                    const dayCount = state.value;
                    const items = s.value;

                    if (items.length < dayCount) {
                        for (let i = items.length; i < dayCount; i++) {
                            items.push({
                                date: '',
                                time: '',
                            });
                        }
                    } else if (items && items.length > dayCount) {
                        items.splice(dayCount, items.length - dayCount);
                    }

                    s.display = dayCount > 0 ? 'visible' : 'hidden';
                });
            });
        },
    }), []);

    useEffect(() => {
        if (personnel) {
            form.setInitialValues({
                ...personnel,
                'photo_url': personnel.photo_url
                    ? {
                        uid: personnel.id,
                        name: personnel.photo_url,
                        status: 'done',
                        url: fixDockerHost(personnel.photo_url),
                        photo_url: fixDockerHost(personnel.photo_url),
                    }
                    : undefined,
            });
        }

        observables.photo_url = fixDockerHost(personnel?.photo_url);
    }, [form, observables, personnel, personnel?.id]);

    const handleUploadedPhotoChange = (fileList: UploadFile[]) => {
        const file = fileList[0];
        if (file.status === 'uploading') {
            observables.photo_loading = true;

            return;
        }
        if (file.status === 'done') {
            observables.photo_loading = false;
            observables.photo_url = fixDockerHost(file.response.photo_url);
        }
    };

    const PersonnelPhotoUploader = observer(() => (
        <SchemaField.Markup
            x-decorator="FormItem"
            x-component="Upload"
            x-component-props={{
                listType: 'picture-card',
                className: 'avatar-uploader',
                showUploadList: false,
                customRequest: customUploadRequest(uploadEmployeeProfilePhoto),
                beforeUpload: validateUploadedPhoto,
                onChange: handleUploadedPhotoChange,
            }}
            x-reactions={(field) => {
                field.content = (() => {
                    if (observables.photo_loading) {
                        return <LoadingOutlined/>;
                    }

                    if (observables.photo_url) {
                        return (
                            <img
                                src={observables.photo_url}
                                style={styles.photoPreview}
                                alt="Upload preview"
                            />
                        );
                    }

                    return <PlusOutlined/>;
                })();
            }}
            name="photo_url"
        />
    ));

    const personnelInformationCard = (
        <SchemaField.Void
            x-component="Card"
            x-component-props={{ title: t('form.personnel-information.title') }}
            name="cxk691otwox"
        >
            <SchemaField.Void
                x-component="a"
                x-component-props={{ id: sections.personalInformation, href: `#` }}
            />
            <SchemaField.Void
                x-component="FormGrid"
                name="cp8vokiqbt1"
            >
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    x-component-props={{
                        style: {
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                    }}
                    name="g469h00msf9"
                >
                    <PersonnelPhotoUploader/>
                </SchemaField.Void>
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="pr3zl47p330"
                >
                    <SchemaField.String
                        title={t('form.personnel-information.first-name')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                            id: 'first_name',
                        }}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="first_name"
                    />
                    <SchemaField.String
                        title={t('form.personnel-information.last-name')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                            id: 'last_name',
                        }}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="last_name"
                    />
                    <SchemaField.String
                        title={t('form.personnel-information.birth-name')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="birth_name"
                    />
                </SchemaField.Void>
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="sylsu0ikgd5"
                >
                    <SchemaField.String
                        title={t('form.personnel-information.email-address')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                            id: 'email_address',
                        }}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                            {
                                format: 'email',
                            },
                        ]}
                        name="email_address"
                    />
                    <SchemaField.String
                        title={t('form.personnel-information.phone-number')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                            id: 'phone_number',
                        }}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="phone_number"
                    />
                </SchemaField.Void>
            </SchemaField.Void>
        </SchemaField.Void>
    );

    const addressInformationCard = (
        <SchemaField.Void
            x-component="Card"
            x-component-props={{ title: t('form.address-information.title') }}
            name="767fkq1ee6r"
        >
            <SchemaField.Void
                x-component="a"
                x-component-props={{ id: sections.addressInformation, href: `#` }}
            />
            <SchemaField.Void
                x-component="FormGrid"
                name="imnklcp211x"
            >
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="zunv0o1ld9d"
                >
                    <SchemaField.String
                        title={t('form.address-information.address-street')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="address_street"
                    />
                    <SchemaField.String
                        title={t('form.address-information.address-number')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="address_number"
                    />
                </SchemaField.Void>
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="no548s94ff4"
                >
                    <SchemaField.String
                        title={t('form.address-information.address-postcode')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="address_postcode"
                    />
                    <SchemaField.String
                        title={t('form.address-information.address-city')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="address_city"
                    />
                </SchemaField.Void>
            </SchemaField.Void>
        </SchemaField.Void>
    );

    const passportInformationCard = (
        <SchemaField.Void
            x-component="Card"
            x-component-props={{ title: t('form.passport-information.title') }}
            name="t8riufhe6sj"
        >
            <SchemaField.Void
                x-component="a"
                x-component-props={{ id: sections.passportInformation, href: `#` }}
            />
            <SchemaField.Void
                x-component="FormGrid"
                name="m0njwz63vsw"
            >
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="sguhotveowp"
                >
                    <SchemaField.String
                        title={t('form.passport-information.birth-date')}
                        x-decorator="FormItem"
                        x-component="DatePicker"
                        x-component-props={{
                            placeholder: ct('input.date.placeholder'),
                            format: ct('input.date.format'),
                        }}
                        name="birth_date"
                    />
                    <SchemaField.String
                        title={t('form.passport-information.birth-city')}
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        x-decorator="FormItem"
                        x-component="Input"
                        name="birth_city"
                    />
                    <SchemaField.String
                        title={t('form.passport-information.birth-country')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="birth_country"
                    />
                </SchemaField.Void>
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="krd7bki1ghr"
                >
                    <SchemaField.String
                        title={t('form.passport-information.nationality')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="nationality"
                    />
                    <SchemaField.String
                        title={t('form.passport-information.ethnicity')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="ethnicity"
                    />
                    <SchemaField.String
                        title={t('form.passport-information.residence-permit.title')}
                        x-decorator="FormItem"
                        x-component="TreeSelect"
                        x-component-props={{
                            placeholder: ct('input.select.placeholder'),
                            treeLine: true,
                            allowClear: true,
                        }}
                        name="residence_permit"
                        enum={residencePermitTypes(t)}
                    />
                    <SchemaField.String
                        title={t('form.passport-information.residence-permit-expiration-date')}
                        x-decorator="FormItem"
                        x-component="DatePicker"
                        x-component-props={{
                            placeholder: ct('input.date.placeholder'),
                            format: ct('input.date.format'),
                            allowClear: true,
                        }}
                        name="residence_permit_expiration_date"
                    />
                    <SchemaField.String
                        title={t('form.passport-information.identity-document.title')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            placeholder: ct('input.select.placeholder'),
                            allowClear: true,
                        }}
                        name="identity_document_type"
                        enum={[
                            {
                                label: t('form.passport-information.identity-document.types.national-id'),
                                value: 'national_id',
                            },
                            {
                                label: t('form.passport-information.identity-document.types.passport'),
                                value: 'passport',
                            },
                        ]}
                    />
                    <SchemaField.String
                        title={t('form.passport-information.identity-document-number')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="identity_document_number"
                    />
                    <SchemaField.String
                        title={t('form.passport-information.identity-document-expiration-date')}
                        x-decorator="FormItem"
                        x-component="DatePicker"
                        x-component-props={{
                            placeholder: ct('input.date.placeholder'),
                            format: ct('input.date.format'),
                        }}
                        name="identity_document_expiration_date"
                    />
                </SchemaField.Void>
            </SchemaField.Void>
        </SchemaField.Void>
    );

    const taxInformationCard = (
        <SchemaField.Void
            x-component="Card"
            x-component-props={{
                title: t('form.tax-and-social-security-information.title'),
            }}
            name="p4jzszdkzx5"
        >
            <SchemaField.Void
                x-component="a"
                x-component-props={{ id: sections.taxAndSocialSecurityInformation, href: `#` }}
            />
            <SchemaField.Void
                x-component="FormGrid"
                name="5eoqw14goyf"
            >
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="pms9rulmunu"
                >
                    <SchemaField.String
                        title={t('form.tax-and-social-security-information.tax-id')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="tax_id"
                    />
                    <SchemaField.String
                        title={t('form.tax-and-social-security-information.marital-status.title')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            placeholder: ct('input.select.placeholder'),
                            allowClear: true,
                        }}
                        enum={maritalStatusTypes(t)}
                        name="marital_status"
                    />
                    <SchemaField.Boolean
                        title={t('form.tax-and-social-security-information.has-children.title')}
                        x-decorator="FormItem"
                        x-component="Switch"
                        x-component-props={{
                            checkedChildren: t('form.tax-and-social-security-information.has-children.options.yes'),
                            unCheckedChildren: t('form.tax-and-social-security-information.has-children.options.no'),
                        }}
                        name="has_children"
                    />
                    <SchemaField.Number
                        title={t('form.tax-and-social-security-information.children-count')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={{
                            placeholder: ct('input.number.placeholder'),
                            min: 0,
                            max: 50,
                            step: 1,
                        }}
                        name="children_count"
                    />
                    <SchemaField.Number
                        title={t('form.tax-and-social-security-information.child-allowance')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={{
                            placeholder: ct('input.number.placeholder'),
                            step: 0.5,
                            min: 0,
                        }}
                        name="child_allowance"
                    />
                    <SchemaField.Array
                        name="employee_children"
                        title={t('form.tax-and-social-security-information.employee-children.title')}
                        x-decorator="FormItem"
                        x-component="ArrayItems"
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        x-display="hidden"
                    >
                        <SchemaField.Object>
                            <SchemaField.Void x-component="Space">
                                <SchemaField.String
                                    x-decorator="FormItem"
                                    name="name"
                                    x-component="Input"
                                    x-component-props={{
                                        placeholder: t('form.tax-and-social-security-information.employee-children.name.placeholder'),
                                    }}
                                />
                                <SchemaField.String
                                    x-decorator="FormItem"
                                    x-validator={[
                                        {
                                            required: true,
                                            message: ct('validation.required'),
                                        },
                                    ]}
                                    name="birth_date"
                                    x-component="DatePicker"
                                    x-component-props={{
                                        id: React.useId(),
                                        format: ct('input.date.format'),
                                        placeholder: t('form.tax-and-social-security-information.employee-children.birth_date.placeholder'),
                                        style: {
                                            width: 160,
                                        },
                                    }}
                                />
                                <SchemaField.Void
                                    x-decorator="FormItem"
                                    x-component="ArrayItems.Remove"
                                />
                            </SchemaField.Void>
                        </SchemaField.Object>
                    </SchemaField.Array>
                </SchemaField.Void>
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="e2hof8n2lmk"
                >
                    <SchemaField.String
                        title={t('form.tax-and-social-security-information.health-insurance-company-name')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="health_insurance_company_name"
                    />
                    <SchemaField.String
                        title={t('form.tax-and-social-security-information.health-insurance-number')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="health_insurance_number"
                    />
                    <SchemaField.String
                        title={t('form.tax-and-social-security-information.social-security-number')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="social_security_number"
                    />
                    <SchemaField.Boolean
                        title={t('form.tax-and-social-security-information.other-employment.title')}
                        x-decorator="FormItem"
                        x-component="Switch"
                        x-component-props={{
                            checkedChildren: t('form.tax-and-social-security-information.other-employment.options.yes'),
                            unCheckedChildren: t('form.tax-and-social-security-information.other-employment.options.no'),
                        }}
                        name="other_employment"
                    />
                    <SchemaField.String
                        title={t('form.tax-and-social-security-information.other-employment-type.title')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            allowClear: true,
                            placeholder: ct('input.select.placeholder'),
                        }}
                        enum={otherEmploymentTypes(t)}
                        name="other_employment_type"
                    />
                    <SchemaField.String
                        title={t('form.tax-and-social-security-information.other-employment-employer')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="other_employment_employer"
                    />
                </SchemaField.Void>
            </SchemaField.Void>
        </SchemaField.Void>
    );

    const paymentInformationCard = (
        <SchemaField.Void
            x-component="Card"
            x-component-props={{ title: t('form.payment-information.title') }}
            name="2booz42u4ib"
        >
            <SchemaField.Void
                x-component="a"
                x-component-props={{ id: sections.paymentInformation, href: `#` }}
            />
            <SchemaField.Void
                x-component="FormGrid"
                name="p1gj2cbvd6p"
            >
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="m1mr7vcnz3a"
                >
                    <SchemaField.String
                        title={t('form.payment-information.bank-account-holder')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="bank_account_holder"
                    />
                    <SchemaField.String
                        title={t('form.payment-information.bank-name')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="bank_name"
                    />
                    <SchemaField.String
                        title={t('form.payment-information.different-bank-account-holder')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="different_bank_account_holder"
                    />
                </SchemaField.Void>
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="a47lx88brfu"
                >
                    <SchemaField.String
                        title={t('form.payment-information.bank-iban')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="bank_iban"
                    />
                    <SchemaField.String
                        title={t('form.payment-information.bank-bic')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="bank_bic"
                    />
                </SchemaField.Void>
            </SchemaField.Void>
        </SchemaField.Void>
    );

    const workInformationCard = (
        <SchemaField.Void
            x-component="Card"
            x-component-props={{ title: t('form.work-information.title') }}
            name="hx9pmesjqrl"
        >
            <SchemaField.Void
                x-component="a"
                x-component-props={{ id: sections.workInformation, href: `#` }}
            />
            <SchemaField.Void
                x-component="FormGrid"
                name="johgl98jygg"
            >
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="97yskpptyyg"
                >
                    <SchemaField.String
                        title={t('form.work-information.application-date')}
                        x-decorator="FormItem"
                        x-component="DatePicker"
                        x-component-props={{
                            placeholder: ct('input.date.placeholder'),
                            format: ct('input.date.format'),
                        }}
                        name="application_date"
                    />
                    <SchemaField.Number
                        title={t('form.work-information.application-role-id')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            allowClear: true,
                            placeholder: ct('input.number.placeholder'),
                        }}
                        name="application_role_id"
                    />
                    <SchemaField.Number
                        title={t('form.work-information.probation-branch-id')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            allowClear: true,
                            placeholder: ct('input.number.placeholder'),
                        }}
                        name="probation_branch_id"
                    />
                    <SchemaField.String
                        title={t('form.work-information.probation-start-date')}
                        x-decorator="FormItem"
                        x-component="DatePicker"
                        x-component-props={{
                            format: ct('input.date.format'),
                        }}
                        name="probation_start_date"
                    />
                    <SchemaField.Number
                        title={t('form.work-information.probation-days.title')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            allowClear: true,
                            placeholder: ct('input.number.placeholder'),
                        }}
                        enum={[1, 2, 3].map((value) => ({
                            label: t(`form.work-information.probation-days.options.${value}`),
                            value,
                        }))}
                        name="probation_day_count"
                    />
                    <SchemaField.Array
                        name="probation_days"
                        title={t('form.work-information.probation-days.title')}
                        x-decorator="FormItem"
                        x-component="ArrayItems"
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        x-display="hidden"
                    >
                        <SchemaField.Object>
                            <SchemaField.Void x-component="Space">
                                <SchemaField.String
                                    x-decorator="FormItem"
                                    x-validator={[
                                        {
                                            required: true,
                                            message: ct('validation.required'),
                                        },
                                    ]}
                                    name="date"
                                    x-component="DatePicker"
                                    x-component-props={{
                                        format: ct('input.date.format'),
                                        style: {
                                            width: 160,
                                        },
                                    }}
                                />
                                <SchemaField.String
                                    x-decorator="FormItem"
                                    x-validator={[
                                        {
                                            required: true,
                                            message: ct('validation.required'),
                                        },
                                    ]}
                                    name="time"
                                    x-component="TimePicker"
                                    x-component-props={{
                                        format: 'HH:mm',
                                    }}
                                />
                                <SchemaField.Void
                                    x-decorator="FormItem"
                                    x-component="ArrayItems.Remove"
                                />
                            </SchemaField.Void>
                        </SchemaField.Object>
                    </SchemaField.Array>
                    <SchemaField.String
                        title={t('form.work-information.start-date')}
                        x-decorator="FormItem"
                        x-component="DatePicker"
                        x-component-props={{
                            placeholder: ct('input.date.placeholder'),
                            format: ct('input.date.format'),
                        }}
                        name="start_date"
                    />
                    <SchemaField.String
                        title={t('form.work-information.end-date')}
                        x-decorator="FormItem"
                        x-component="DatePicker"
                        x-component-props={{
                            placeholder: ct('input.date.placeholder'),
                            format: ct('input.date.format'),
                        }}
                        name="end_date"
                    />
                </SchemaField.Void>
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="l9e2hphccl1"
                >
                    <SchemaField.String
                        title={t('form.work-information.personnel-number')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="personnel_number"
                    />
                    <SchemaField.Boolean
                        title={t('form.work-information.has-driving-license.title')}
                        x-decorator="FormItem"
                        x-component="Switch"
                        x-component-props={{
                            checkedChildren: t('form.work-information.has-driving-license.options.yes'),
                            unCheckedChildren: t('form.work-information.has-driving-license.options.no'),
                        }}
                        name="has_driving_license"
                    />
                    <SchemaField.String
                        title={t('form.work-information.driving-license-number')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="driving_license_number"
                    />
                    <SchemaField.String
                        title={t('form.work-information.education-school')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            placeholder: ct('input.tags.placeholder'),
                            labelInValue: false,
                            mode: 'tags',
                            showSearch: true,
                            filterOption: false,
                            tokenSeparators: [','],
                        }}
                        name="education_school"
                    />
                    <SchemaField.String
                        title={t('form.work-information.tasks')}
                        x-decorator="FormItem"
                        x-component="Input.TextArea"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="tasks"
                    />
                    <SchemaField.Markup
                        title={t('form.work-information.skills')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            placeholder: ct('input.tags.placeholder'),
                            labelInValue: false,
                            mode: 'tags',
                            showSearch: true,
                            filterOption: false,
                            tokenSeparators: [','],
                        }}
                        name="skills"
                    />
                    <SchemaField.String
                        title={t('form.work-information.work-experience')}
                        x-decorator="FormItem"
                        x-component="Input.TextArea"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="work_experience"
                    />
                </SchemaField.Void>
            </SchemaField.Void>
        </SchemaField.Void>
    );

    const contractInformationCard = (
        <SchemaField.Void
            x-component="Card"
            x-component-props={{ title: t('form.contract-information.title') }}
            x-decorator={undefined}
            name="bnjopsyti7t-new"
        >
            <SchemaField.Void
                x-component="a"
                x-component-props={{ id: sections.contractInformation, href: `#` }}
            />
            <SchemaField.Array
                name="contracts"
                x-decorator="FormItem"
                x-component="ArrayTable"
                x-component-props={{
                    scroll: { x: '100%' },
                }}
            >
                <SchemaField.Object>
                    <SchemaField.Void
                        x-component="ArrayTable.Column"
                        x-component-props={{
                            width: 80,
                            title: ct('index'),
                            align: 'center',
                        }}
                    >
                        <SchemaField.Void
                            x-decorator="FormItem"
                            x-component="ArrayTable.Index"
                        />
                    </SchemaField.Void>
                    <SchemaField.Void
                        x-component="ArrayTable.Column"
                        x-component-props={{
                            title: t('form.contract-information.contract-form.title'),
                            width: 200,
                        }}
                    >
                        <SchemaField.String
                            name="form"
                            x-decorator="FormItem"
                            x-component="TranslatedText"
                            x-component-props={{
                                ns: 'personnel-files',
                                options: {
                                    keyPrefix: 'form.contract-information.contract-form.options',
                                },
                                empty: '-',
                            }}
                            required
                        />
                    </SchemaField.Void>
                    <SchemaField.Void
                        x-component="ArrayTable.Column"
                        x-component-props={{
                            title: t('form.contract-information.primary-branch-id'),
                            width: 200,
                        }}
                    >
                        <SchemaField.String
                            name="branch_name"
                            x-decorator="FormItem"
                            x-component="Text"
                            required
                        />
                    </SchemaField.Void>
                    <SchemaField.Void
                        x-component="ArrayTable.Column"
                        x-component-props={{
                            title: t('form.contract-information.primary-work-area-id'),
                            width: 200,
                        }}
                    >
                        <SchemaField.String
                            name="work_area_name"
                            x-decorator="FormItem"
                            x-component="Text"
                        />
                    </SchemaField.Void>
                    <SchemaField.Void
                        x-component="ArrayTable.Column"
                        x-component-props={{
                            title: t('form.contract-information.primary-role-id'),
                            width: 200,
                        }}
                    >
                        <SchemaField.String
                            x-decorator="FormItem"
                            name="role_name"
                            x-component="Text"
                        />
                    </SchemaField.Void>
                    <SchemaField.Void
                        x-component="ArrayTable.Column"
                        x-component-props={{
                            title: ct('actions'),
                            width: 200,
                            fixed: 'right',
                        }}
                    >
                        <SchemaField.Void
                            x-component="EditContractButton"
                            x-component-props={{
                                onChange: (index: number, values: any) => {
                                    const { contracts } = form.getState().values;

                                    contracts[index] = values;
                                    form.setValues({ contracts });
                                },
                            }}
                        />
                        <SchemaField.Void
                            x-component="DeleteContractButton"
                            x-component-props={{
                                onClick: (index: number) => {
                                    const { contracts } = form.getState().values;

                                    contracts.splice(index, 1);
                                    form.setValues({ contracts });
                                },
                                style: {
                                    marginLeft: 8,
                                },
                            }}
                        />
                    </SchemaField.Void>
                </SchemaField.Object>
                <SchemaField.Void
                    x-component="ArrayTable.Addition"
                    title={ct('add')}
                />
            </SchemaField.Array>
        </SchemaField.Void>
    );

    const healthInformationCard = (
        <SchemaField.Void
            x-component="Card"
            x-component-props={{ title: t('form.personal-health-information.title') }}
            name="rop3dlaigwg"
        >
            <SchemaField.Void
                x-component="a"
                x-component-props={{ id: sections.personalHealthInformation, href: `#` }}
            />
            <SchemaField.Void
                x-component="FormGrid"
                name="4z50djzq06u"
            >
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="75k9svisk7t"
                >
                    <SchemaField.String
                        title={t('form.personal-health-information.hobbies')}
                        x-decorator="FormItem"
                        x-component="Input.TextArea"
                        x-component-props={{
                            placeholder: ct('input.number.placeholder'),
                        }}
                        name="hobbies"
                    />
                    <SchemaField.Number
                        title={t('form.personal-health-information.height')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={{
                            min: 0,
                            max: 500,
                            placeholder: ct('input.number.placeholder'),
                            addonAfter: 'cm',
                            step: 1,
                        }}
                        name="health_height"
                    />
                    <SchemaField.Number
                        title={t('form.personal-health-information.weight')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={{
                            min: 0,
                            max: 500,
                            placeholder: ct('input.number.placeholder'),
                            addonAfter: 'kg',
                            step: 0.5,
                        }}
                        name="health_weight"
                    />
                    <SchemaField.Markup
                        title={t('form.personal-health-information.blood-type')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            showSearch: true,
                            placeholder: ct('input.select.placeholder'),
                            allowClear: true,
                        }}
                        name="health_blood_type"
                        enum={[
                            { label: '0 rh(+)', value: '0+' },
                            { label: '0 rh(-)', value: '0-' },
                            { label: 'A rh(+)', value: 'A+' },
                            { label: 'A rh(-)', value: 'A-' },
                            { label: 'B rh(+)', value: 'B+' },
                            { label: 'B rh(-)', value: 'B-' },
                            { label: 'AB rh(+)', value: 'AB+' },
                            { label: 'AB rh(-)', value: 'AB-' },
                        ]}
                    />
                </SchemaField.Void>
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="kuvuedzqgq9"
                >
                    <SchemaField.Markup
                        title={t('form.personal-health-information.diseases')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            mode: 'tags',
                            allowClear: true,
                            placeholder: ct('input.select.placeholder'),
                            tokenSeparators: [','],
                        }}
                        name="health_diseases"
                    />
                    <SchemaField.Markup
                        title={t('form.personal-health-information.medications')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            mode: 'tags',
                            allowClear: true,
                            placeholder: ct('input.select.placeholder'),
                            tokenSeparators: [','],
                        }}
                        name="health_medications"
                    />
                    <SchemaField.Markup
                        title={t('form.personal-health-information.addictions')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            mode: 'tags',
                            allowClear: true,
                            placeholder: ct('input.select.placeholder'),
                            tokenSeparators: [','],
                        }}
                        name="health_addictions"
                    />
                </SchemaField.Void>
            </SchemaField.Void>
        </SchemaField.Void>
    );

    const healthPassportCard = (
        <SchemaField.Void
            x-component="Card"
            x-component-props={{ title: t('form.health-passport-information.title') }}
            name="0nwm9h3plza"
        >
            <SchemaField.Void
                x-component="a"
                x-component-props={{ id: sections.healthPassportInformation, href: `#` }}
            />
            <SchemaField.Void
                x-component="FormGrid"
                name="p1gj2cbvd6p"
            >
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="m1mr7vcnz3a"
                >
                    <SchemaField.Boolean
                        title={t('form.health-passport-information.has-health-passport')}
                        x-decorator="FormItem"
                        x-component="Switch"
                        name="has_health_passport"
                    />
                </SchemaField.Void>
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="a47lx88brfu"
                >
                    <SchemaField.String
                        title={t('form.health-passport-information.health-passport-issue-date')}
                        x-decorator="FormItem"
                        x-component="DatePicker"
                        name="health_passport_issue_date"
                    />
                    <SchemaField.String
                        title={t('form.health-passport-information.health-passport-expiration-date')}
                        x-decorator="FormItem"
                        x-component="DatePicker"
                        name="health_passport_expiration_date"
                    />
                </SchemaField.Void>
            </SchemaField.Void>
        </SchemaField.Void>
    );

    const emergencyContactCard = (
        <SchemaField.Void
            x-component="Card"
            x-component-props={{ title: t('form.emergency-contact-information.title') }}
            name="0nwf9h3plzs"
        >
            <SchemaField.Void
                x-component="a"
                x-component-props={{ id: sections.emergencyContactInformation, href: `#` }}
            />
            <SchemaField.Void
                x-component="FormGrid"
                name="4n2abfzcf37"
            >
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="8x7tckf6jcz"
                >
                    <SchemaField.String
                        title={t('form.emergency-contact-information.name')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="emergency_contact_name"
                    />
                    <SchemaField.String
                        title={t('form.emergency-contact-information.relationship')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="emergency_contact_relationship"
                    />
                </SchemaField.Void>
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="ajcfpzy3pz8"
                >
                    <SchemaField.String
                        title={t('form.emergency-contact-information.phone-number')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="emergency_contact_phone_number"
                    />
                    <SchemaField.String
                        title={t('form.emergency-contact-information.email-address')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        x-validator={[
                            {
                                message: ct('validation.required'),
                            },
                            {
                                format: 'email',
                            },
                        ]}
                        name="emergency_contact_email_address"
                    />
                    <SchemaField.String
                        title={t('form.emergency-contact-information.address')}
                        x-decorator="FormItem"
                        x-component="Input.TextArea"
                        x-component-props={{
                            placeholder: ct('input.text.placeholder'),
                        }}
                        name="emergency_contact_address"
                    />
                </SchemaField.Void>
            </SchemaField.Void>
        </SchemaField.Void>
    );

    return (
        <>
            <Form
                form={form}
                labelCol={6}
                wrapperCol={12}
                onAutoSubmit={handleSubmit}
                onAutoSubmitFailed={(feedbacks) => {
                    const targetField = form.query(feedbacks[0].path as FormPathPattern).take();

                    // @ts-ignore
                    const targetId = targetField?.component[1]?.id;
                    if (targetId) {
                        document.getElementById(targetId)?.focus();
                    }
                }}
            >
                <SchemaField>
                    {personnelInformationCard}
                    {addressInformationCard}
                    {passportInformationCard}
                    {taxInformationCard}
                    {paymentInformationCard}
                    {workInformationCard}
                    {contractInformationCard}
                    {healthInformationCard}
                    {healthPassportCard}
                    {emergencyContactCard}
                </SchemaField>
                <FormButtonGroup.Sticky
                    align="center"
                    style={styles.footerContainer}
                >
                    <Submit block size="large" style={styles.submitButton}>
                        {ct('submit')}
                    </Submit>
                </FormButtonGroup.Sticky>
            </Form>
        </>
    );
};

export default PersonnelForm;
