import { FieldDataSource, FormPathPattern, GeneralField, onFieldInit, onFieldReact } from "@formily/core";
import { AxiosPromise } from "axios";
import { ListedData } from "../types";
import { action, observable } from '@formily/reactive';

export const getAsyncDataSourceWithLookupHandler = (axiosPromise: (keyword: any) => AxiosPromise<ListedData[]>) =>
    async ({ keyword }: { keyword: any }) => {
        return new Promise<FieldDataSource>((resolve) => {
            axiosPromise(keyword).then((data) => {
                resolve(data.data.map((item) => ({
                    label: item.name,
                    value: item.name,
                })));
            });
        });
    };

export const asyncDataSourceWithLookup = (
    pattern: FormPathPattern,
    service: (param: {
        keyword: string
        field: GeneralField
    }) => Promise<FieldDataSource>,
) => {
    const keyword = observable.ref('');

    onFieldInit(pattern, (field) => {
        field.setComponentProps({
            onSearch: (value: string) => {
                keyword.value = value;
            },
        });
    });

    onFieldReact(pattern, (field: GeneralField) => {
        // @ts-ignore
        field.loading = true;
        service({ field, keyword: keyword.value }).then(
            action.bound?.((data) => {
                // @ts-ignore
                field.dataSource = data;
                // @ts-ignore
                field.loading = false;
            }),
        );
    });
};