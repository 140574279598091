import React from 'react';
import {
    ScheduleOutlined,
    ToolOutlined,
    DashboardTwoTone,
    ContainerOutlined,
    TeamOutlined,
    BankOutlined,
    RocketOutlined,
    ShopOutlined,
    CodeSandboxOutlined,
    BarChartOutlined,
    SnippetsOutlined,
    EyeOutlined,
    FundTwoTone, HomeOutlined,
} from '@ant-design/icons';
import i18n from './i18n';

type Route = {
    path: string;
    name: string;
    icon?: React.ReactNode|string;
    component?: React.ReactNode;
    access?: string;
    routes?: Route[];
    showInMenu?: boolean;
}

type DefaultProps = {
    route: {
        routes: Route[];
    },
    location: {
        pathname: string;
    }
}

const { t } = i18n;

const defaultProps: DefaultProps = {
    route: {
        routes: [
            {
                path: '/',
                name: t('menu.activities.title'),
                icon: <DashboardTwoTone />,
                routes: [
                    {
                        path: '/activities/dashboard',
                        name: t('menu.activities.dashboard.title'),
                        icon: <BarChartOutlined />,
                    },
                    {
                        path: '/activities/daily-report',
                        name: t('menu.activities.daily-report.title'),
                        icon: <EyeOutlined />,
                    },
                    {
                        path: '/activities/documentation',
                        name: t('menu.activities.documentation.title'),
                        icon: <SnippetsOutlined />,
                    }
                ]
            },
            {
                path: '/planning',
                name: t('menu.planning.title'),
                icon: <ScheduleOutlined />,
                routes: [
                    {
                        path: 'personnel',
                        name: t('menu.planning.personnel-planning.title'),
                        icon: <TeamOutlined style={{ color: '#08c' }} />,
                    },
                    {
                        path: 'supplier',
                        name: t('menu.planning.supplier-planning.title'),
                        icon: <ShopOutlined style={{ color: '#52c41a' }} />,
                    },
                    {
                        path: 'service',
                        name: t('menu.planning.service-planning.title'),
                        icon: <ToolOutlined style={{ color: '#eb2f96' }} />,
                    },
                ],
            },
            {
                name: t('menu.files.title'),
                icon: <ContainerOutlined />,
                path: '/files',
                routes: [
                    {
                        path: 'personnel',
                        name: t('menu.files.personnel-files.title'),
                        icon: <TeamOutlined style={{ color: '#08c' }} />,
                    },
                    {
                        path: 'supplier',
                        name: t('menu.files.supplier-files.title'),
                        icon: <ShopOutlined style={{ color: '#52c41a' }} />,
                    },
                    {
                        path: 'service',
                        name: t('menu.files.service-files.title'),
                        icon: <ToolOutlined style={{ color: '#eb2f96' }} />,
                    },
                ],
            },
            {
                name: t('menu.finance.title'),
                icon: <BankOutlined />,
                path: '/finance',
                routes: [
                    {
                        path: 'general',
                        name: t('menu.finance.general-finances.title'),
                        icon: <FundTwoTone />,
                    },
                    {
                        path: 'personnel',
                        name: t('menu.finance.personnel-finances.title'),
                        icon: <TeamOutlined style={{ color: '#08c' }} />,
                    },
                    {
                        path: 'supplier',
                        name: t('menu.finance.supplier-finances.title'),
                        icon: <ShopOutlined style={{ color: '#52c41a' }} />,
                    },
                    {
                        path: 'service',
                        name: t('menu.finance.service-finances.title'),
                        icon: <ToolOutlined style={{ color: '#eb2f96' }} />,
                    },
                ],
            },
            {
                name: t('menu.inventory.title'),
                icon: <CodeSandboxOutlined />,
                path: '/inventory',
                routes: [
                    {
                        path: 'warehouse',
                        name: t('menu.inventory.warehouses.title'),
                        icon: <HomeOutlined />,
                    },
                ],
            },
            {
                name: t('menu.start-up.title'),
                icon: <RocketOutlined />,
                path: '/start-up',
                routes: [
                ],
            },
        ],
    },
    location: {
        pathname: '/',
    },
};

export default defaultProps;
