import { MenuProps } from 'antd/lib';
import { CompassOutlined, ContactsOutlined, FireOutlined, FormOutlined, ProductOutlined } from '@ant-design/icons';

type MenuItem = Required<MenuProps>['items'][number];

export const createMenuItems = (t: any, disableDetails: boolean): MenuItem[] => ([
    {
        key: 'supplier-information',
        label: t('menu.supplier-information'),
        icon: <FormOutlined />,
      },
      {
        key: 'supplier-contacts',
        label: t('menu.contacts'),
        icon: <ContactsOutlined />,
        disabled: disableDetails,
      },
      {
        key: 'supplier-addresses',
        label: t('menu.addresses'),
        icon: <CompassOutlined />,
        disabled: disableDetails,
      },
      {
          type: 'divider',
      },
      {
        key: 'supplier-performance-product-basics',
        label: t('menu.performance-product-basics'),
        icon: <FireOutlined />,
        disabled: disableDetails,
      },
      {
          key: 'supplier-loan-products',
          label: t('menu.loan-products'),
          icon: <ProductOutlined />,
          disabled: disableDetails,
      },
]);