import React, { useState, useMemo } from 'react';
import { createForm, FieldDataSource, FormPathPattern, onFieldValueChange } from '@formily/core';
import { ArrayItems, Switch, Form, FormItem, Input, TreeSelect } from '@formily/antd-v5';
import { createSchemaField } from '@formily/react';
import { useTranslation } from 'react-i18next';
import { Card, Alert, Space, Button, message } from 'antd';
import {
    asyncDataSourceWithLookup,
} from '../../../utils/asyncDataSourceWithLookup';
import {
    getListCompaniesWithBranches,
    WarehouseRequest,
    WarehouseResource,
} from '../../../utils/network/api/v1';
import { AxiosPromise } from 'axios';

type WarehouseFormProps = {
    w?: any;
    loading?: boolean;
    store: (values: WarehouseRequest) => AxiosPromise<WarehouseResource>,
    afterSubmit: (values: any) => void;
}

const SchemaField = createSchemaField({
    components: {
        Card,
        FormItem,
        Input,
        ArrayItems,
        TreeSelect,
        Switch,
        Alert,
        Space,
        Button,
    },
});

type CompaniesWithBranches = {
    id: string;
    name: string;
    branches: {
        id: string;
        name: string;
    }[];
}

export const getCompaniesWithBranchesAsyncHandler = (axiosPromise: (keyword: any) => AxiosPromise<CompaniesWithBranches[]>) =>
    async ({ keyword }: { keyword: any }) => {
        return new Promise<FieldDataSource>((resolve) => {
            axiosPromise(keyword).then((data) => {
                resolve(data.data.map((item) => ({
                    title: item.name,
                    value: item.name,
                    selectable: false,
                    children: item.branches?.map((branch) => ({
                        title: branch.name,
                        value: branch.id,
                    })),
                })));
            });
        });
    };

const WarehouseForm: React.FC<WarehouseFormProps> = ({w, store, afterSubmit}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const autoSubmit = (values: any) => {
        setLoading(true);

        store(values).then((response) => {
            if ([200, 201].includes(response.status)) {
                afterSubmit(values);
            } else {
                console.error('Error submitting form:', response);
            }
        }).catch((e) => {
            if (e.response && e.response.status === 422) {
                const errors = e.response.data.errors;

                // Map Laravel validation errors to Formily's error format
                const formErrors = Object.keys(errors).reduce((acc, field) => {
                    acc[field] = errors[field].join(' ');  // Join multiple error messages for the same field
                    return acc;
                }, {} as Record<string, string>);

                // Set the errors on the form
                Object.keys(formErrors).forEach((field) => {
                    form.setFieldState(field, (state) => {
                        state.feedbacks = [
                            {
                                triggerType: 'onFocus',
                                type: 'error',
                                messages: [formErrors[field]],
                            },
                        ];
                    });
                });

            } else {
                message.error(e.message)
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const { t } = useTranslation('inventory', {
        keyPrefix: 'warehouse.form',
    });
    const { t: ct } = useTranslation('common');
    const form = useMemo(() => createForm({
        effects: () => {
            onFieldValueChange('*', (field) => {
                field.feedbacks = [];
            })
            // @ts-ignore
            asyncDataSourceWithLookup('branches', getCompaniesWithBranchesAsyncHandler(getListCompaniesWithBranches))
        },
        initialValues: w,
    }), [w]);

    return (
        <Form
            form={form}
            labelCol={3}
            onAutoSubmit={autoSubmit}
            onAutoSubmitFailed={(feedbacks) => {
                const targetField = form.query(feedbacks[0].path as FormPathPattern).take();

                // @ts-ignore
                const targetId = targetField?.component[1]?.id;
                if (targetId) {
                        document.getElementById(targetId)?.focus();
                }
            }}
        >
            <SchemaField>
                <SchemaField.Void x-component="Card" x-component-props={{ title: t('general.title') }}>
                        <SchemaField.String
                            title={t('name')}
                            x-decorator="FormItem"
                            x-component="Input"
                            x-disabled={loading}
                            x-validator={{
                                required: true,
                                max: 255,
                            }}
                            x-component-props={{
                                placeholder: ct('input.text.placeholder'),
                                id: 'name',
                            }}
                            name="name"
                        />
                        {/*<SchemaField.Boolean*/}
                        {/*    title={t('is_active')}*/}
                        {/*    x-decorator="FormItem"*/}
                        {/*    x-component="Switch"*/}
                        {/*    x-disabled={loading}*/}
                        {/*    x-component-props={{*/}
                        {/*        id: 'is_active',*/}
                        {/*    }}*/}
                        {/*    name="is_active"*/}
                        {/*/>*/}
                </SchemaField.Void>

                <SchemaField.Void x-component="Card" x-component-props={{ title: t('configuration.title') }}>
                    <SchemaField.Void
                        x-component="Alert"
                        x-component-props={{
                            message: t('configuration.alert.message'),
                            type: 'info',
                            showIcon: true,
                            style: { marginBottom: '16px' },
                        }}
                    />
                    <SchemaField.String
                        title={t('configuration.branches.title')}
                        x-decorator="FormItem"
                        x-component="TreeSelect"
                        x-disabled={loading}
                        x-component-props={{
                            placeholder: ct('input.select.placeholder'),
                            treeLine: true,
                            allowClear: true,
                            multiple: true,
                        }}
                        name="branches"
                    />
                </SchemaField.Void>

                <SchemaField.Void>
                    <SchemaField.Void x-component="div" x-component-props={{
                        style: { display: 'flex', justifyContent: 'flex-end', marginTop: 16 },
                    }}>
                        <SchemaField.Void
                            x-component="Button"
                            x-component-props={{
                                type: 'primary',
                                htmlType: 'submit',
                                children: ct('submit'),
                            }}
                        />
                    </SchemaField.Void>
                </SchemaField.Void>
            </SchemaField>
        </Form>
        )
}

export default WarehouseForm;