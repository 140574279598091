import { InventoryTranslations } from '../../types';

const Inventory: InventoryTranslations = {
    'warehouse': {
        'index': {
            'name': 'Name',
            'branches': 'Branches',
        },
        'form': {
            'general': {
                'title': 'General',
            },
            'name': 'Name',
            'branches': 'Branches',
        }
    },
}

export default Inventory;