import React from 'react';
import { PageContainer } from '@ant-design/pro-components';
import WarehouseForm from '../WarehouseForm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { postWarehouse } from '../../../utils/network/api/v1';

const NewWarehouseForm: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('inventory', {
        keyPrefix: 'warehouse',
    });

    return (
        <PageContainer title={t('create.title')}>
            <WarehouseForm
                store={postWarehouse}
                afterSubmit={_ => {
                    navigate({
                        pathname: '/inventory/warehouse',
                        // search: createSearchParams({name: values.name}).toString(),
                    });
                }}
            />
        </PageContainer>
    );
}

export default NewWarehouseForm;