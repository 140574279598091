import { Card, Button, message } from 'antd';
import { createSchemaField } from '@formily/react';
import { createForm, FormPathPattern } from '@formily/core';
import { FormGrid, GridColumn, Form, FormLayout, FormItem, Input, DatePicker } from '@formily/antd-v5';
import React, { useMemo } from 'react';
import { AddressResource, storeSupplierAddress, updateSupplierAddress } from '../../../../../../../utils/network/api/v1';
import { useTranslation } from 'react-i18next';

type SupplierAddressResource = {
    id?: number;
}

const creator = (supplierId: number, afterSubmit: (data: SupplierAddressResource) => void) => (values: any) =>
    storeSupplierAddress(supplierId, values).then((data) => {
        afterSubmit(data.data);
    });

const updator = (supplierId: number, id: number, afterSubmit: (data: SupplierAddressResource) => void) => (values: any) =>
    updateSupplierAddress(supplierId, id, values).then((data) => {
        afterSubmit(data.data);
    })

type SupplierAddressFormProps = {
    supplierId: number,
    address?: AddressResource;
    onSubmit: () => void;
    onCancel: () => void;
}

const SchemaField = createSchemaField({
    components: {
        Button,
        FormItem,
        FormGrid,
        GridColumn,
        Card,
        Input,
        DatePicker,
    },
});

const SupplierAddressForm: React.FC<SupplierAddressFormProps> = ({ supplierId, address: c, onSubmit, onCancel }) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [, setValidationErrors] = React.useState<any>();
    const form = useMemo(() => createForm({
        initialValues: c,
    }), [c]);

    const { t } = useTranslation('supplier-files', {
        keyPrefix: 'form.supplier-address',
    });
    const { t: ct } = useTranslation('common');

    const afterSubmit = async (values: any) => {
        if (loading) return;
        
        const cb = (data: any) => {
        };
        
        
        const sendData =  !c?.id ? creator(supplierId, cb) : updator(supplierId, c!.id, cb);
        
        setLoading(true);
        let errValidation: any = [];
        sendData(values).then(() => {
            message.success(ct('message.success'), 10);
            onSubmit();
        }).catch((e) => {
            message.error(ct('message.error'), 10);

            if (e.response && e.response?.status === 422) {
                errValidation = e.response?.data?.errors;

                window.scrollTo(0, 0);
            }
        }).finally(() => {
            setLoading(false);
            setValidationErrors(errValidation);
        });
    };
    
    return (
        <Form
            form={form}
            labelCol={8}
            wrapperCol={14}
            onAutoSubmit={afterSubmit}
            onAutoSubmitFailed={(feedbacks) => {
                const targetField = form.query(feedbacks[0].path as FormPathPattern).take();

                // @ts-ignore
                const targetId = targetField?.component[1]?.id;
                if (targetId) {
                    document.getElementById(targetId)?.focus();
                }
            }}
            >
                <FormLayout layout="vertical">
                <SchemaField>
                    <SchemaField.Void x-component="Card">
                        <SchemaField.Void x-component="FormGrid">
                            <SchemaField.String
                                title={t('street')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'street',
                                }}
                                name="street"
                            />
                            <SchemaField.String
                                title={t('number')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'number',
                                }}
                                name="number"
                            />
                        </SchemaField.Void>

                        <SchemaField.Void x-component="FormGrid">
                            <SchemaField.String
                                title={t('country')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'country',
                                }}
                                name="country"
                            />
                            <SchemaField.String
                                title={t('city')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'city',
                                }}
                                name="city"
                            />
                            <SchemaField.String
                                title={t('zip_code')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-component-props={{
                                    placeholder: ct('input.text.placeholder'),
                                    id: 'zip_code',
                                }}
                                name="zip_code"
                            />
                        </SchemaField.Void>

                        <SchemaField.Void
                            x-component="div"
                            x-component-props={{
                                style: { display: 'flex', justifyContent: 'flex-end' },
                            }}
                        >
                            <SchemaField.Void
                                x-disabled={loading}
                                x-component="Button"
                                x-component-props={{
                                    type: 'primary',
                                    htmlType: 'submit',
                                    children: ct('submit'),
                                }}
                                name="submit"
                            />
                            <SchemaField.Void
                                x-component="Button"
                                x-component-props={{
                                    onClick: () => onCancel(),
                                    children: ct('cancel'),
                                }}
                                name="cancel"
                            />
                        </SchemaField.Void>
                    </SchemaField.Void>

                </SchemaField>
                </FormLayout>
            </Form>
    );
}

export default SupplierAddressForm;